import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

export const initSlider = () => {
    new Swiper('.swiper-container', {
        modules: [Pagination],
        parallax: true,
        setWrapperSize: true,
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        grabCursor: true,
        observer: true,
        observeParents: true,
        pagination: {
            el: '.swiper--pagination',
            dynamicBullets: false,
            clickable: true,
        },
    });
};
